
// EU Cookie Compliance Message (D8)

#sliding-popup{
  position:fixed;
  z-index:9999999999; // Ensure the cookie message overlays the live chat.. and the other 'important' widgedts with senseless high z-index
  bottom:0;
  left:0;
  width:100% !important;
  background:$gray-darker !important;
  color:$white;
  padding:15px 10px;
  font-size:$medium-font-size;
  .popup-content{
    background:none;
    color: $white;
    border: 0 none;
    max-width:$page-max-width;
    margin:0 auto;
    background:none;
    &__inner{
      display:flex;
      justify-content: space-between;
      align-items:center;
      flex-flow:row wrap;
      margin-left: -1rem;
      margin-right: -1rem;
      @media #{$large-up}{
        flex-flow: nowrap;
      }
      > *{
        padding-left: 1rem;
        padding-right:1rem;
      }
    }
    @media #{$medium-up}{
      flex-wrap:nowrap;
    }
    &::before{
      display:none;
    }
    #popup-text{
      margin-bottom:$global-margin;
      min-width:300px;
      max-width:100vw;
      @media #{$medium-up}{
        padding-right:$space-medium-h;
        margin-bottom:0;
      }
      h2,
      h3{
        font-size:1em;
      }
      a{
        color:$highlight-text-color;
      }
      p{
        font-size:1em;
        font-weight:$font-weight-normal;
        color:$white !important;
        display:inline;
        &:last-of-type{
          margin:0;
          display: inline;
        }
      }
      .find-more-button{
        background:none;
        border:0 none;
        padding:0;
        text-decoration:underline;
        font-size: 1em !important;
        cursor:pointer;
        color:currentColor;
        &:hover{
          color: currentColor;
          text-decoration:underline;
        }
      }
    }
    .eu-cookie-compliance-categories{
      min-width:280px;
    }
    .eu-cookie-compliance-category{
      margin-top:.65em;
      margin-bottom:.65em;
      &__option{
        display:flex;
        align-items:start;
        input[type="checkbox"]{
          flex: 0 0 auto;
          margin: 0 .15rem 0 0 !important;
          &[disabled]{
            opacity:.75;
          }
          &:checked:not([disabled]){
            filter: $brand-success;
            + label{
              color: $brand-success;
            }
          }
        }
        label{
          flex: 1 1 auto;
          padding:0 !important;
          margin:0 !important;
          line-height:1.1;
        }
      }
      &:not(:last-child){
        margin-bottom:.35em;
      }
    }
    .eu-cookie-compliance-category-description{
      font-size: $small-font-size;
      line-height: 1.15;
      opacity:.65;
    }
    #popup-buttons{
      display:flex;
      @media #{$small-only}{
        flex-direction:column;
        flex:1;
      }
      button{
        margin-bottom:2px !important;
        white-space:nowrap;
        &:not(.agree-button){
          // @include button(false, $light-color, $success-color, $light-color, hollow);
        }
        &.agree-button{
          // @include button(false, $button-background, $button-background-hover, $white, solid);
        }
        // &:not(:first-child){
        //   @media #{$medium-up}{
        //     margin-left:5px;
        //   }
        // }
      }
    }
    // Show buttons vertical if cookie categories present
    .eu-cookie-compliance-categories + #popup-buttons{
      display:block;
      .button{
        display:block;
        width:100% !important;
        margin-top:.2em !important;
        margin-bottom:.2em !important;
        text-align:center !important;
        font-size: $medium-font-size !important;
        &:not(.agree-button){
          padding-top:.35em !important;
          padding-bottom:.35em !important;
        }
        &.agree-button{
          border-color:$white !important;
          font-weight: $font-weight-bold !important;
          &:hover{
            background-color: $white !important;
            color: $text-color !important;
          }
        }
      }
    }
  }
  // Revoke bar / button
  .eu-cookie-withdraw-tab{
    font-size: $font-small-px;
    padding:.75em;
    text-align:center;
    text-align: center;
    display: block;
    width: 100%;
    left: 0;
    top:0;
    transform: translate(0,-100%);
    @include media-breakpoint-up(sm) {
      left: $page-outer-grid;
      width:auto;
      display:inline;
    }
  }
  &.eu-cookie-withdraw-wrapper{
    padding-top:0;
    padding-bottom:0;
    .eu-cookie-withdraw-banner{
      background: none;
      padding-top:15px;
      padding-bottom:15px;
    }
    .popup-content{
      display:flex;
      align-items:center;
      justify-content:center;
      flex-flow:row wrap;
      > *{
        padding-left:1rem;
        padding-right:1rem;
      }
    }
  }
  // Modifier classes from the module stylesheets
  .eu-cookie-compliance-hidden {
    display: none !important;
  }
}
