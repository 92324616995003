// _base.scss imports _custom.scss (variables and mixins) and other partials.
@import "base";
html {
  background-color: $body-background;
}

body {
  color: $text-color;
  overflow-x: hidden;
  background: transparent;
}

// Responsive Images

img {
  // we define it here, cause the global.base will be loaded for the wysiwyg editor also - so this attributes will destroy image resizing functions
  width: auto;
  height: auto;
}

// =============================================================================
//  Wrappers
// =============================================================================

.page {
  @if($full-width-page==false) {
    background: $page-background;
    margin: 0 auto;
  }
}

.page-header {
  background-color: $page-background;

  >.inner {
    padding-top: $space-thin-v;
    padding-bottom: $space-thin-v;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @include media-breakpoint-up(md) {
      justify-content: flex-start;
      min-height: 143px;
      background-image: url("../images/silhouette_minden_porta.svg");
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: contain;
    }

    @include media-breakpoint-up(lg) {
      background-size: 69%;
      background-position: right 0 bottom 3px;
    }

    &::after,
    &::before {
      display: none !important;
    }
  }

  // Marken
  #block-views-km-marken-block-1 {
    width: 100%;
    text-align: center;

    @include media-breakpoint-up(md) {
      position: absolute;
      bottom: 2px;
      right: 0;
    }

    @include media-breakpoint-up(lg) {
      right: 0;
      width: 69%; // Same as background-size width!
    }
  }
}

.page-columns-outer {
  // background-image:url("images/stripe_bg_light.png");
  // background-image:url("images/hamburg_silh.svg");
  // background-repeat:no-repeat;
  // background-position:left 68px;
  // background-position: center top;
  // background-size:contain;
}

.front {
  .page-columns-outer {
    // background-position:left 50%;
    background-position: center center;
    background-size: cover;
    padding-top: 0;
  }

  .page-content .region-wrapper--middle {
    background: $grey;
    position: relative;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    margin-bottom: $space-medium-v;
    padding-top: $space-medium-v;

    .region-wrapper-inner {
      max-width: $page-max-width - $grid;
      margin-left: auto;
      margin-right: auto;
    }

    &::after {
      // undo clearfix style
      height: 1px;
      background: $grey-light;
      visibility: visible;
      display: block;
      // make full width
      position: relative;
      left: 50%;
      width: 100vw;
      margin-left: -50vw;
    }
  }
}

// .not-front{
//   .page-columns-outer{
//     // background-position:left 50%;
//     background-position: center 10%;
//   }
// }

.page-footer {
  background: $black;
  background-image: url("images/stripe_bg_dark.png");
  background-repeat: no-repeat;
  background-position: right 0 top 0;
  background-size: cover;
  color: $highlight-text-color;

  >.inner {
    background-color: transparent;
  }

  a,
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $highlight-text-color;
  }

  .menu {
    a {
      color: $grey;
      padding-left: 0;
      padding-right: 0;

      &:hover {
        color: $highlight-text-color;
      }
    }
  }
}

// =============================================================================
//  Branding
// =============================================================================
// Wraps all the branding elements, logo, name and slogan

.branding {
  display: none; // logo is already present inside the headroom bar on small devices
  max-width: 265px;
  width:100%;
  margin: 0;
  div,
  a{
    display: block;
    width:100%;
  }
  @include media-breakpoint-up($headroom-docking-permanent-breakpoint) {
    display: flex;
    align-items: center;

    &::after,
    &::before {
      display: none !important;
    }
  }
}

// Logo

.page-logo {
  padding: 0;
  text-align: center;

  // IE11 bugix - not vertical centering by parent attribute align-items:center;
  @include media-breakpoint-up(lg) {

    // IE11 Bufix (not verticaly centering the logo by parents align-items:center)
    &:not(.page-logo--headroom) {
      line-height: 113px;

      img {
        vertical-align: middle;
      }
    }
  }

  a {
    display: block;
  }
}

// Site name shown in the header

.page-name {
  margin: 0;
}

// Site slogan shown in the header

.page-slogan {
  margin: 0;
}

// =============================================================================
//  Animations (CSS)
// =============================================================================
// Using Animate.css library https://daneden.github.io/animate.css/ > see libraries/animate.scss to enable specific animations
// To use the animations simply include the mixin animated and the animation name eg.: @include animation-name(bounce);
// Some custom animations
// -- Always fade colors

*,
*::after,
*::before {
  transition-property: color, background-color, border-color;
  transition-duration: $transition-duration-default;
}

// -- Pulsating Glow
@keyframes pulsating-glow {
  0% {
    box-shadow: 0 0 3px $danger;
  }

  50% {
    box-shadow: 0 0 10px $danger;
  }

  100% {
    box-shadow: 0 0 3px $danger;
  }
}

// -- Dragging complete (eg. Sticky Tables)
@keyframes dragging-complete {
  0% {
    background: $info;
  }

  100% {
    background: $white;
  }
}

// -- Swipe In (eg. mobile menu trail)
@keyframes swipe-in {
  0% {
    transform: translate(-150px, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

// =============================================================================
//  Regions
// =============================================================================

.region {

  // All Regions
  &-inner {
    // Inner Wrapper
  }
}

.region-header {
  @include media-breakpoint-up(lg) {
    margin-left: auto;
  }

  >.region-inner {

    &::after,
    &::before {
      display: none !important;
    }
  }

  // Contents
  // -- Secondary Head Menu
  .menu {
    @include horizontal-menu();
    @include tiny-menu('horizontal');
  }

  // -- Search
  .block-search {
    margin-bottom: 0;
    margin-top: 15px;

    @if($headroom-searchblock==true) {
      display: none;
    }

    @include media-breakpoint-up(md) {
      float: right;

      @if($headroom-searchblock==true) {
        display: block;
      }
    }
  }
}

.region-header-slide {
  clear: both;
}

.navigation-bar-wrapper {
  clear: both;
  background: $page-background;
  border-top: 1px solid $grey;

  >.region-inner {
    max-width: $page-max-width;
    margin: 0 auto;
  }
}

.sidebar {
  .block-title {
    text-align: center;
  }
}

.sidebar-second {

  // Sloped line beyond main menu bar - only if we have a right sidebar
  .navigation-bar-wrapper>.inner {
    @include media-breakpoint-up(lg) {
      position: relative;

      &::before {
        content: " ";
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIzNjhweCIgaGVpZ2h0PSI0NTZweCIgdmlld0JveD0iMCAwIDM2OCA0NTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM2OCA0NTYiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwb2x5Z29uIGZpbGw9IiM2MjYzNjYiIHBvaW50cz0iMjkuOCwwIDAsMCAwLDg2IDM1Ny41LDQ1NiAzNjgsNDU2IDM2OCwxODUgIi8+PC9zdmc+");
        background-repeat: no-repeat;
        background-color: $white;
        background-size: 33px 42px;
        height: 42px;
        width: 33.3333%;
        box-shadow: 300px 0 0 $white, 600px 0 0 $white, 900px 0 0 $white;
        display: block;
        position: absolute;
        right: 25px;
        bottom: -42px;
        z-index: 2;
      }

      &::after {
        content: " ";
        background-color: $black;
        box-shadow: 300px 0 0 $black, 600px 0 0 $black, 900px 0 0 $black;
        height: 25px;
        width: calc(33.3333% - 8px);
        display: block;
        position: absolute;
        right: 0;
        bottom: -42px;
        z-index: 3;
      }
    }
  }

  // Reserve space for the absolut positioned pseudo elements
  .second-sidebar-wrapper {
    padding-top: 20px;
  }
}

.not-front {
  .navigation-bar-wrapper {
    border-bottom: 8px solid $black;
  }
}

.region-navigation-bar {
  >.region-inner {
    @if $navbar-searchblock {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &::after,
      &::before {
        display: none !important;
      }

      .block {

        &.block-block-3,
        &.block-search,
        &.block-search-form {

          // block-3 = Offcanvas Trigger
          @include media-breakpoint-up(sm) {
            float: right; // flex fallback
            margin-right: 0;
          }
        }
      }
    }
  }
}

.flexbox,
.flexboxlegacy {
  .region-navigation-bar {
    >.region-inner {
      &::after {
        // Remove clearfix as this fucks flexbox layout (but we need it for the fallback)
        display: none;
      }
    }
  }
}

.region-help {
  .block {
    @include box-style('info');
  }
}

.region-sidebar-first {
  // Left Sidebar
}

.region-sidebar-second {
  // Right Sidebar
}

.region-above-footer {
  >.region-inner {
    // display:flex;
    // flex-direction:column-reverse; // move the map above ..
  }
}

// Bottom (full width)

.region-bottom {
  @include cutline(top, $space-large-v);
}

.region-footer {
  >.region-inner {
    padding: $space-medium-v 0 $space-thin-v;

    &::after,
    &::before {
      display: none !important;
    }
  }
}

.region-footer-bottom {
  border-top: 1px solid lighten($black, 8%);
  background: $black;
  color: $highlight-text-color;

  @include media-breakpoint-down(xl) {
    // reserve space for the contact bar docked to bottom
    padding-bottom: 62px;
  }

  a {
    color: $highlight-text-color;
  }
}

.block-menu-menu-footer-menu {
  .menu {
    text-align: center;

    li {
      display: block;

      @include media-breakpoint-up(lg) {
        display: inline-block;
      }

      a {
        color: $grey-light;
        padding-top: $space-thin-v;
        padding-bottom: $space-thin-v;

        @include media-breakpoint-up(lg) {
          padding-top: $space-medium-v;
          padding-bottom: $space-medium-v;
        }

        &:hover {
          color: $highlight-text-color;
        }
      }
    }
  }
}

// =============================================================================
//  Block (& Panel Pane) Styling
// =============================================================================

.block {

  // All Blocks
  &-inner {
    // Inner Wrapper
  }

  &-title {
    margin-top: 0;
  }

  &-content {
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.panel-pane {
  // All Panes
  margin-bottom: $space-medium-v;

  .pane-title {
    margin-top: 0;
  }

  .pane-content {
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.pane--btn {
  // panes holding only buttons (eg. Frontpage top)
  height: 100%;
  padding-bottom: $space-thin-v;
  margin-bottom: 0;

  @include media-breakpoint-up(lg) {
    padding-bottom: $space-medium-v;
  }

  .pane-content {
    height: 100%;
  }
}

// Formatter

.block,
.panel-pane {
  &--right {
    // Align flex items right
    margin-left: auto;
    margin-right: 3px;
    float: right; // fallback - flexbox will ignore this
  }
}

// Black line title

.stripe-title-wrapper {
  max-width: 100%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  padding-left: $page-outer-grid;
  padding-right: $page-outer-grid;
  position: relative;
  z-index: 2;
  margin-bottom: -15px;
  @include clearfix-after;

  @include media-breakpoint-up(lg) {
    max-width: $page-max-width;
    overflow: visible;
  }

  .block-title {
    color: $highlight-text-color;
    margin: 0 $page-outer-grid * -1;
    display: flex;
    align-items: flex-start;

    @include media-breakpoint-up(lg) {
      margin-left: 0;
      margin-right: 0;
    }

    &__inner {
      display: block;
      // padding:.35em $page-outer-grid;
      background: $highlight;
      // text-align:center;
      white-space: nowrap; // important! do not set white-space:nowrap & overflow hidden etc. this will force flexbox to cut the title off!
      overflow: hidden;
      text-overflow: ellipsis;
      float: left; // flex fallback
      order: 0;
      flex-grow: 1;
      text-align: left;
      padding: .35em $thin-grid .35em $page-outer-grid;
      font-size: $font-size-px;
      height: 45px;
      line-height: 34px;
      vertical-align: middle;

      @include media-breakpoint-up(sm) {
        font-size: $font-size-h2;
        padding-left: $thin-grid;
        padding-right: $thin-grid;
        overflow: visible;
      }
    }

    &::before {
      @include media-breakpoint-up(lg) {
        content: " ";
        background: $highlight;
        height: 15px;
        box-shadow: 300px 0 0 $highlight, 600px 0 0 $highlight;
        width: 100%;
        float: right; // flex fallback
        order: 2;
        flex-shrink: 1;
        align-self: flex-end;
      }
    }

    &::after {
      content: " ";
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIxNzZweCIgaGVpZ2h0PSIyMzMuM3B4IiB2aWV3Qm94PSIwIDAgMTc2IDIzMy4zIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxNzYgMjMzLjMiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnIGlkPSJFYmVuZV8yIj48cG9seWdvbiBmaWxsPSIjNjI2MzY2IiBwb2ludHM9IjE3NiwyMzMuNCAxMDIsMjMzLjQgMCwxNzMuNzAxIDAsLTEuMyAxNzYsMTczLjcwMSAiLz48L2c+PC9zdmc+");
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      height: 59px;
      width: 45px;
      margin-left: -1px; // prevent ugly gap
      margin-right: -1px; // prevent ugly gap
      min-width: 45px; // flex fix
      min-height: 59px; // flex fix
      float: right; // flex fallback
      order: 1;
    }
  }
}

// Block Specific
// -- Header Image
.views-field-field-headerimage {
  line-height: 0;
}

.block-views-header-slideshow-block {
  line-height: 0;
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.view-header-slideshow {
  position: relative;
  // background: $highlight;
  // @include media-breakpoint-up(lg) {
  //   background:transparent;
  // }

  img {
    @if $full-width-page {
      // Show slideshow image on 100% width - even if its overscaled.
      width: 100% !important;
      min-height: 100px;
      max-height: 80vh;
      max-width: none;
      object-fit: cover;
      object-position: 50% 68%;
    }
  }

  div[class*="item-"] {
    // non js classes! the hide thing beyond may be deprecated (with .owl-item)
    display: none; // hide on load
  }

  div.item-0 {
    display: block; // show first image also if js not ready
  }

  // Show items after owl-slider is initialized
  .owl-item {
    div[class*="item-"] {
      display: flex;
      flex-direction: column;
      height: 100%;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }

  .view-empty {
    padding: 0;
    border: 0 none;
    border-radius: 0;
  }

  .owl-dots {
    @include media-breakpoint-down(lg) {
      display: none !important;
      // bottom: auto;
      // top: $space-thin-v;
    }
  }
}

// -- Overlay
// #webksde-TF: L/R positioning removed (on customer request)
.header-image-overlay {
  background: none;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  text-align: center;
  flex: 1;

  @include media-breakpoint-up(lg) {
    align-items: center;
    justify-content: center;
    background-image: none;
  }

  &__box {
    // max-width:$page-max-width;
    padding: $space-thin-v $page-outer-grid;
    width: 100%; // fix width on items with less content

    @include media-breakpoint-up(lg) {
      text-align: center;
      font-size: $font-size-large;
      line-height: $line-height-medium;
      padding: $space-thin-v $space-thin-h;
      margin-left: $grid * 2;
      margin-right: $grid * 2;
      margin-top: auto; // align bottom
      margin-bottom: $grid * 2; // reserve space for the dot navi
    }
  }

  &__content {
    color: $highlight-text-color;
    text-shadow: 1px 1px 2px $text-shadow-intensity;

    h2{
      @include media-breakpoint-down(lg) {
        font-size:1rem;
      }
    }
    h2,
    h3 {
      color: $highlight-text-color;
    }
    .field-name-body{
      border: 0 none !important;
      margin:0 !important;
      @include media-breakpoint-down(lg) {
        line-height:1.1;
      }
    }
    .btn {
      @include btn-size('xl');
      text-transform: uppercase;

      @include media-breakpoint-down(md) {
        @include btn-mod('ghost-dark');
      }
    }

    p {
      margin-top: 0;
    }
  }

  &__title {
    margin-top: 0;
    // font-weight:$font-weight-extra-bold;
    margin-bottom: .25em;
    color: $highlight-text-color;
    font-weight: $font-weight-bold;

    @include media-breakpoint-up(lg) {
      font-size: $font-xlarge-px;
      line-height: $line-height-xlarge;
    }
  }

  &--has-text{
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.45) 100%);
    @include media-breakpoint-up(lg) {
      background-image:none;
    }
  }
  &--left {
    justify-content: flex-start;
    text-align: left;

    .header-image-overlay__box {
      @include media-breakpoint-up(lg) {
        // left: $space-thin-h;
        // text-align: left;
        max-width: 500px;
      }

      @include media-breakpoint-up(xl) {
        max-width: 50vw;
      }
    }
  }

  &--right {
    justify-content: flex-end;
    text-align: right;

    .header-image-overlay__box {
      @include media-breakpoint-up(lg) {
        right: $space-thin-h;
        text-align: right;
        max-width: 500px;
      }

      @include media-breakpoint-up(xl) {
        max-width: 50vw;
        margin-right: $grid * 3; // prevent overlay text appearing under the richt docked contact bar
      }
    }
  }

  &--invert-text {
    @include media-breakpoint-up(lg) {
      color: $text-color !important;
      text-shadow: none !important;

      * {
        color: $text-color !important;
        text-shadow: none !important;
      }
    }
  }
}

.header-image__overlay-link {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  text-indent: -9000px;
  text-align: left;
}

// -- -- Header Image BLOCK "Matching Images" (node edit)

.header-image--matching-images {
  @include box-style;
}

// Contact Bar

.block-menu-menu-call-to-action-quicklinks {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 98;
  bottom: 32px; // reserved space for the cookie settings button (.eu-cookie-withdraw-tab)
  left: 0;
  width: 100%;
  border-bottom:1px solid darken($highlight,8%);

  @include media-breakpoint-up(sm) {
    bottom:0;
    border-bottom:0 none;
  }
  @include media-breakpoint-up(xl) {
    bottom: auto;
    right: 0;
    top: 50%;
    left: auto;
    width: auto;
    margin-top: -75px;
  }

  .block-content {
    background: $highlight;
    color: $highlight-text-color;
    border-color: $grey-light;
    border-width: 1px 0 0;
    border-style: solid;
    padding: $space-thin-v $space-thin-h;
    width: 100%;
    min-width: 200px;

    @include media-breakpoint-up(sm) {
      width: auto;
      border-width: 1px 1px 0;
    }

    @include media-breakpoint-up(xl) {
      min-width: 0;
      padding: 25px $space-thin-h;
      border-width: 0;
    }
  }

  .menu {
    display: flex;
    justify-content: space-between;

    &::after {
      display: none !important; // remove clearfix
    }

    li {
      &.last {
        a {
          margin-bottom: 0;
        }
      }
    }

    a {
      color: $highlight-text-color;

      &.ico-seperate {
        width: 42px;
      }

      @include media-breakpoint-up(xl) {
        margin-right: 0;
        margin-bottom: 25px;
      }
    }

    @include media-breakpoint-up(xl) {
      flex-direction: column;
    }
  }
}

.front {
  .block-menu-menu-call-to-action-quicklinks {
    @include media-breakpoint-up(xl) {
      bottom: $grid * 4;
      top: auto;
      margin-top: 0;
    }
  }
}

// CTA
#block-panels-mini-cta-below-content {
  @include media-breakpoint-up(lg) {
    margin-bottom: $space-thin-v;
  }

  .block-content {
    background-image: url("images/pixabay_mountain_road_1556177.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: ($space-medium-v * 2) 0 $space-medium-v;

    a:not(.btn),
    h3 {
      color: $white;
      text-decoration: none;
    }
  }

  .panel-display {
    max-width: $page-max-width;
    margin-left: auto;
    margin-right: auto;
    padding-left: $page-outer-grid;
    padding-right: $page-outer-grid;
  }

  .region {
    .pane-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .btn {
        transition-property: transform;
        transition-timing-function: linear;
      }

      >a:last-child {
        color: rgba($white, .6);
      }

      &:hover {
        .btn {
          transform: scale(1.1);
        }

        >a:last-child {
          color: $white;
        }
      }
    }

    h3 {
      margin-bottom: 0;
    }
  }
}

// Anfahrt & Kontakt Mini Panel

#block-panels-mini-footer-map-overlay,
#block-panels-mini-lp-wkda-footer-map-overlay {
  position: relative;
  margin-bottom: 0;
  overflow: hidden; // the openstreetmap produces a hor. scrollbar
  @include media-breakpoint-down(xl) {
    margin-bottom: 2em;
  }
  @include media-breakpoint-up(xl) {
    padding: $space-large-v 0;
  }

  .block-content {
    @include media-breakpoint-up(xl) {
      max-width: $page-max-width;
      margin-left: auto;
      margin-right: auto;
      padding-left: $page-outer-grid;
      padding-right: $page-outer-grid;
    }
  }

  .region-two-50-top {
    > .region-inner > .panel-pane{
      margin-bottom:0;
    }
    + .region-cols{
      padding: $grid;
      background: $black;
      color: $highlight-text-color;
      position:relative;
      z-index:2;
      margin-left:0;
      margin-right:0;
      &:hover {
        background: $black;
      }

      @include media-breakpoint-up(xl) {
        padding: $grid ($grid * 2);
        max-width: 55%;
        margin-left: auto;
        display: flex;
        background: $black-glass;
      }
    }
  }

  .panel-display {
    a,
    h2,
    h3,
    h4 {
      color: $highlight-text-color;
    }

    .panel-pane {
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }

    .pane-title {
      font-size: $font-size-h2;
    }

    h3 {
      font-size: $font-size-px;
      margin-top: 0;
    }

    .btn {
      // invert button colors
      @include btn-mod('inverted');
    }

    .opening-hours {
      margin-bottom: 1em;
      display: flex;
      flex-wrap: wrap;

      .days {
        width: 40%;
      }

      .time {
        width: 60%;
        white-space: nowrap;
      }
    }
  }

  // Contact data
  // -- Hide some parts to archive a more equal height to the right column
  .contact-container .item:nth-of-type(4),
  strong[itemprop="name"] {
    display: none;
  }

  // Map
  .pane--map {
    position: relative !important; // kill contextual links
    margin: ($grid * -1) ($grid * -1) $space-medium-v; // make full width

    @include media-breakpoint-up(xl) {
      margin: 0 0 $space-medium-v;
      position: static !important;
    }

    .contextual-links-wrapper {
      display: none !important;
    }
  }

  .btn--map-expand {
    position: absolute;
    left: $thin-grid;
    bottom: $thin-grid;
  }

  // New DSGVO conform map solution (OSM screenshot)
  .image-map{
    min-width: 100%;
    display: block;
    @include media-breakpoint-up(xl) {
      position: absolute;
      left: -500px;
      top: 0;
      width: calc(100vw + 500px);
      height: 100%;
      max-width: none;
      z-index: 1;
    }
  }

  // OLD styles for the embeded gmap iframe
  // iframe {
  //   // to simply re-center the map marker next to the black contact box, we move the entire map to the left.
  //   object-fit: fill;
  //   object-position: center;
  //   filter: grayscale(.85);
  //   min-width: 100%;
  //   display: block;

  //   @include media-breakpoint-up(xl) {
  //     position: absolute;
  //     left: -500px;
  //     top: 0;
  //     width: calc(100vw + 500px); // TODO: we may need a fallback here
  //     height: 100%;
  //     max-width: none;
  //     z-index: 1;
  //   }
  // }

  // Remove all contextual links stuff here - otherwise the positioning fails
  .contextual-links-region {
    position: static;

    .contextual-links-wrapper {
      display: none !important;
    }
  }
}
#block-panels-mini-lp-wkda-footer-map-overlay {
  .region-two-50-top + .region-cols{
    .region{
      float:none !important;
      width:auto !important;
      padding-left:0 !important;
      padding-right:0 !important;
    }
    @include media-breakpoint-up(xl) {
      max-width:430px;
    }
  }
}

// Ansprechpartner
// -- Gruppiert - Uebersicht

.view-km-ansprechpartner {
  .view-grouping-content {
    >h2 {
      // hide title of sub groupings
      display: none;
    }
  }
}

// -- Media object (dark theme)

.view-km-ansprechpartner-er {
  &.view-display-id-block_1 {
    .media-object {
      background: $highlight;
      color: $highlight-text-color;
      display: table;
      width: 100%;
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(sm) {
        flex-direction: row;
      }

      @include media-breakpoint-between(md, lg) {
        flex-direction: column;
      }

      &__subline {
        font-size: $font-small-px;
        font-style: italic;
        color: $grey-light;
      }

      &__content {
        padding: $space-thin-v $space-thin-h $space-thin-v $thin-grid;
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      &__image {
        padding-bottom: 0;

        img {
          max-width: none;
          width: 100%;
          height: 100%;
        }

        @include media-breakpoint-up(sm) {
          min-width: 100px;
          max-width: 120px;
        }

        @include media-breakpoint-between(md, lg) {
          max-width: 100%;
          width: 100%;

          img {
            max-width: none;
            width: 100%;
          }
        }

        @include media-breakpoint-up(xl) {
          max-width: 160px;
          min-width: 120px;
          max-height: 200px;
        }

        * {
          height: 100%;
        }

        img {
          object-fit: cover;
        }
      }

      &__further-fields {
        margin-top: auto;
      }

      a,
      h2,
      h3 {
        color: $highlight-text-color;
      }

      h2,
      h3 {
        font-size: 1em;
      }
    }
  }
}

// =============================================================================
//  Field Styling (Output)
// =============================================================================

.field-label {
  font-size: 1em;
  font-weight: $font-weight-normal;
  font-family: inherit;
  line-height: inherit;
  margin-bottom: 0;
}

.field-label-inline {

  .field-item,
  .field-items,
  .field-label {
    display: inline;
  }
}

a.colorbox {
  cursor: zoom-in;
}

// Basic Field Types
// -- Body

.field-name-body {
  @include clearfix-after;

  h2,
  h3 {
    &:first-child {
      margin-top: 0;
      padding-top: 0;
    }
  }
}

// -- Image

.field-type-image {
  .colorbox {
    cursor: zoom-in;
  }

  .field-item {
    line-height: 0;
    text-align: center;
  }
}

// -- -- Titleimage

.field-name-field-titelbild {
  margin-bottom: $grid-vertical;
}

// -- -- Gallery

.field-name-field-galerie {
  margin-top: $grid-vertical;

  .field-item {
    margin-bottom: $grid;

    a {
      overflow: hidden;
      display: block;
    }

    img {
      transition-property: transform, opacity;
    }

    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
}

// Videos

.field-type-video-embed-field {
  .field-item {
    margin-bottom: $space-thin-v;

    .colorbox {
      line-height: 0;
    }
  }
}

// Tags

.field-name-field-tags {
  margin-bottom: 1em;
}

// Named (custom) Fields
// -- Downloads Anhaenge

.field-name-field-attachments {
  margin-bottom: 1.5em;

  .field-items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 ($half-grid * -1);
  }

  .field-item {
    @include media-breakpoint-up(md) {
      width: 50%;
      padding: 0 $half-grid;
    }
  }

  .file {
    display: block;
    padding: 4px 0;
    margin-bottom: 3px;

    &:not(:last-child) {
      border-bottom: 1px solid $grey;
    }

    a {
      @include btn(ghost);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
    }
  }
}

// Contact icon fields (phone, mail, ...)

.field-name-field-email,
.field-name-field-fon {
  white-space: nowrap;
}

// Further Description
.field-name-field-further-description{
  border-top: 1px solid $grey-base;
  padding-top:1.5rem;
  margin-top: 1.5rem;
  font-size: $small-font-size;
}

// =============================================================================
//  Search (Page)
// =============================================================================

.custom-search-filter {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-block;
  }

  a {
    @include btn('ghost');
  }
}

// =============================================================================
//  Node Styling
// =============================================================================
// TODO-Vorlage: This was a project related thing.. remove it.
@mixin node-header {
  text-align: center;

  .page-title {
    display: inline-block;
    line-height: $line-height-medium;
  }

  .field-name-field-subtitle {
    display: inline-block;
    font-size: $h1-font-size;
    line-height: $line-height-medium;
    text-transform: uppercase;
    margin-left: $thin-grid;
  }
}

.node {
  margin-bottom: 20px;

  &__header {
    @include node-header;

    &--has-subline {
      margin-bottom: ceil($h1-font-size * .67);
    }
  }

  .node-title {
    margin: 0;
  }

  &.node-header-image {
    margin-bottom: 0;
  }

  &.view-mode-full {
    .field-name-body {
      margin-bottom: $space-medium-v;
    }
  }
}

// -- Author Info

footer.submitted {
  background: none repeat scroll 0 0 $grey-light;
  clear: both;
  color: $grey;
  margin-top: 1em;
  padding: 10px 10px 10px 15px;
  vertical-align: top;

  .user-picture {
    border: 5px solid $grey-light;
    background: $white;
    float: left;
    line-height: 0;
    margin-right: 15px;
    margin-left: -15px;
    margin-top: -10px;
  }
}

// Ansprechpartner

.node-ansprechpartner {
  .field-name-field-fon {
    .field-label {
      @include ico-replacement('phone');
    }
  }

  .field-name-field-email {
    .field-label {
      @include ico-replacement('mail');
    }
  }
}

// =============================================================================
//  Tokens
// =============================================================================
//  Kontakt

.contact-container .item-content {
  line-height: 20px;
  vertical-align: middle;
}

.contact-container .item {
  display: block;
  margin-bottom: .4em;
}

.contact-container br {
  display: none;
}

// =============================================================================
//  Views Styling
// =============================================================================
// Empty

.view-empty {
  @include box-style;
  font-size: .9em;
  padding: 2em;
  text-align: center;
}

// Header & Footer

.view-header {
  margin-bottom: $grid-vertical;
}

.view-footer {
  margin-top: $grid-vertical;
}

// Attachements

.attachment-before {
  margin-bottom: $grid-vertical;
}

.attachment-after {
  margin-top: $grid-vertical;
}

// Grouping

.view-content,
.view-content>.item-list,
.view-grouping-header {

  >h3,
  >h2 {
    text-align: center;
    @include cutline('top');
    @include cutline('bottom');
  }
}

// Row

.views-row {
  margin-bottom: $grid-vertical;
  @include clearfix-after;

  &.cutline-bottom {
    &:last-child {
      border-bottom: 0 none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

// Item List Stlyes
// Limited to views! .item-list selecot hits way to many lists

.view {
  .item-list {
    >ul {
      margin: 0;
      padding: 0;

      >.views-row {
        list-style: none;
      }
    }
  }
}

// Filter

.view-filters {
  @include cutline('bottom');
}

.views-exposed-form {
  &__button-wrapper {
    display: flex;
    justify-content: space-between;
  }

  // Hide submit button on auto submit filters
  .ctools-auto-submit-full-form .views-submit-button {
    display: none;
  }
}

.views-exposed-widgets {
  display: flex;
  align-items: flex-end;
}

.views-exposed-widget {
  margin-bottom: $thin-grid;

  // Date Filter
  &.views-widget-filter-date_filter {
    >label {
      display: none;
    }
  }

  .date-padding {
    display: block;

    .form-item {
      margin: 0;
    }
  }
}

.views-submit-button {
  margin-left: auto;
}

// Field Types
// -- Image

.view-image {
  text-align: center;
}

// -- Read on links

.more-link,
.read-on {
  text-align: right;
  display: block;
  clear: both;
}

.more-link a,
.read-on a,
a.more-link {
  @include btn('ghost', 'default', 'arrow-thin-right');
}

a.more-link {
  float: right;
  margin-top: $space-thin-v;
}

td.read-on a {
  display: block;
  text-align: left;
  margin-bottom: .25em;
}

// Specific Views
// -- Marken

.view-km-marken {

  // Header
  &.view-display-id-block_1 {
    line-height:1;
    .views-row {
      margin-bottom: 0;

      &::after {
        display: none;
      }

      &:hover {
        transform: scale(1.1);
      }

      &:not(.views-row-1) {
        padding-left: $thin-grid;
      }

      >.inner {
        max-width: 43px;
        a{
          display:block;
        }
        img{
          max-height: 43px;
        }
      }
    }

    .view-content .item-list>ul {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// -- Glossar

.view-id-glossary {
  .views-row {
    clear: both;
    margin-left: $grid / 2;
  }

  .attachment {
    margin-bottom: 1.5em;
    color: $grey;
  }

  .views-summary {
    position: relative;

    a {
      text-decoration: none;
      padding: 3px 5px;
      display: inline-block;
      min-width: 15px;
      text-align: center;
    }

    .count {
      display: none;
    }

    &:hover {
      .count {
        position: absolute;
        bottom: 17px;
        right: -2px;
        padding: 2px;
        border-radius: $border-radius;
        font-size: .65em;
        line-height: normal;
        display: inline;
      }
    }

    &:hover a,
    a.active {
      border-radius: $border-radius-small;
      margin-top: -3px;
      background: $highlight;
      color: $white;
    }

    .view-content {
      a {
        display: inline-block;

        &::first-letter {
          font-size: 1.2em;
          color: $highlight;
        }
      }
    }
  }
}

// Meilensteine
.view-km-historie .item-list {
  padding: 0;
  margin: 0;

  ol {
    margin: 0;
    padding: 0;
  }

  .views-row {
    list-style: none;
    clear: both;
    margin: 0 0 $space-thin-v;
    padding: 0;

    .media-object {
      background: $grey-light;
      border-top: 5px solid $highlight;
      padding: 10px;
      min-height: 55px;

      &__image {
        background: transparent;
      }

      @include box-sizing(border-box);

      @include media-breakpoint-up(lg) {
        width: 75%;
      }

      p {
        margin: 0;
      }
    }

    h2 {
      font-size: 1.2em;
      line-height: normal;
      margin: 0 0 .35em;
      @include transition-duration(200ms);
    }

    .views-field-field-jahr {
      font-weight: $font-weight-bold;
      text-align: center;
      line-height: 50px;
      vertical-align: middle;
      height: 50px;
      position: relative;
      background: $grey-dark;
      color: $white;

      @include media-breakpoint-up(lg) {
        width: 25%;
      }
    }

    &:hover {
      h2 {
        color: $highlight-sec;
      }

      .views-field-field-jahr {
        background: $highlight;
      }
    }

    &.views-row {
      .views-fieldset.inhalt {
        @include media-breakpoint-up(lg) {
          float: left;
        }
      }

      .views-field-field-jahr {

        // @include horizontal-gradient($white, $grey-light);
        @include media-breakpoint-up(lg) {
          float: left;
        }
      }
    }
  }
}

// Fahrzeuge
.page-node-37 {
  .view-km-link-kacheln-er {
    .card__title {
      text-align: center;
    }
  }
}

// =============================================================================
//  Mobile.de Module
// =============================================================================
// Filter

#sufu_daten_wrapper {
  padding-left: 0;

  .col-100 {
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
    }

    label,
    select {
      @include media-breakpoint-down(md) {
        float: none;
        display: block;
        width: 100%;
        margin: 0 0 5px;
      }
    }
  }
}

.mobilede_infos {

  // Suchoptionen
  .buttons {
    margin-bottom: 1em;
    color: transparent; // hide dash between buttons

    >span {
      @include btn();
      margin-right: 4px;

      &[onclick^="mobile_de_show_sufu"] {

        // Button Filter / Suche eingrenzen
        &::before {
          @include get-ico-only('search');
          font-family: $iconfont;
          margin-right: 5px;
        }
      }

      &[onclick^="location.reload"] {

        // Button Reset Filter
        &::before {
          @include get-ico-only('circle-remove');
          font-family: $iconfont;
          margin-right: 5px;
        }
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 4px;
      }
    }
  }

  +.clear {
    display: none;
  }
}

.car_values3 {
  margin-bottom: .35em;
}

// Filteroptionen

#mobilede_sufu_daten {
  >form {
    >.clear {
      display: none;
    }

    >div {
      overflow: hidden; // quick clearfix
      margin-bottom: $space-thin-v;
    }

    label {
      font-weight: $font-weight-bold;
    }

    select {
      +select {
        margin-left: $thin-grid;
      }
    }
  }
}

// Sortieroptionen

#orderbyarea {
  @include btn('ghost', 'xs');
  margin-bottom: 1em;

  &::before {
    font-family: $iconfont;
    @include get-ico-only('sort');
    margin-right: 4px;
  }
}

// Current page

.pager_info {
  float: right;

  +.clear {
    display: none;
  }
}

// Pager

#mobile_de_results .control_bottom a {
  @include btn();
}

// Results

#mobile_de_results .car_img img {
  max-width: 80px;
}

// ======= Fahrzeug Detailansicht =============

#mobile_de_results .car.single_car {
  font-size: $font-size-px;
  padding: 0;

  .back_to_list {
    @include btn('default');
    margin-bottom: 1em;

    &::before {
      @include get-ico-only('arrow-thin-left');
      font-family: $iconfont;
      margin-right: 6px;
    }
  }
  h1 {
    font-size: $font-size-h2;
  }

  hr {
    margin: 1.5em 0;
  }

  #mainimg {
    margin-bottom: $space-thin-v;
  }

  .row.features {
    margin-left: 0;
    margin-right: 0;

    @include media-breakpoint-down(lg) {
      column-count: 3;
    }

    @include media-breakpoint-down(md) {
      column-count: 2;
    }

    @include media-breakpoint-down(sm) {
      column-count: 1;
    }
  }

  // Gallery
  .previmages {
    height: auto;

    img {
      margin-bottom: 12px;
    }
  }

  // Auto uncle inzahlungnahme widget (iframe)
  .au-widget-trade-in{
    margin-top: .5em;
    position: relative;
    left: 13px;
  }

  .col_price{
    .btn{
      display:block;
      margin-bottom:.5rem;
      + br{
        display:none;
      }
    }
  }
}
.financing-calculator{
  border:1px solid $grey-light;
  padding:20px;
  > h2{
    border-bottom:1px solid $highlight-cta-sec;
    margin-top:0;
    padding-bottom:.35em;
  }
}


.single_car_head_wrapper {
  // basic infos wrapper
}

.single_car_description {

  // Dirty, dumb markup here, but we have to deal with it.
  br+strong {
    // strong acts as headlines
    display: block;
    margin-top: 2em;
  }
}

.btn-wrapper--sharing-options {
  padding: 1em 0;
}

.additionalAttributes {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 1em;

  dt {
    width: 60%;
    padding: 8px 12px;
    margin: 0 0 1px;
    background: $highlight;
    color: $highlight-text-color;
  }

  dd {
    width: 40%;
    padding: 8px 12px 0;
    margin: 0 0 1px;

    &:nth-of-type(2n-1) {
      background-color: $grey-light;
    }
  }
}

// =============================================================================
//  Landingpages (subtheme)
// =============================================================================

.node-type-landingpage-standalone{
  display:flex;
  flex-direction:column;
  padding-top: 0 !important;
  #page{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .page-header{
    background:$highlight;
    color:$highlight-text-color;
    >.inner{
      background:none;
    }
  }
  #branding{
    display:block !important;
    max-width:100%;
    line-height:0;
  }
  .region-header-slide{
    width:100%;
    margin-bottom:2.25rem;
    .field-name-field-headerimage > .field-items > .field-item{
      height:300px;
      width:100%;
    }
  }
  .view-header-slideshow img{
    // Needed for objectfitpolyfill (ie11)
    @include media-breakpoint-up(lg) {
      height:300px;
      object-position: 50% 50%;
    }
  }
  .page-footer{
    // align footer bottom - even if the page is very short
    margin-top:auto;
  }
}
// IE 11 support
.no-objectfit {
  .node-type-landingpage-standalone{
    .field-name-field-headerimage>.field-items>.field-item img {
      height: auto;
      position:absolute;
      left:0;
      top:50%;
    }
  }
}
.panel-pane--iframe-wkda{
  margin-bottom:0;
}

// =============================================================================
//  Mutlisite: Wisloh customization
// =============================================================================

.multisite-wisloh {
  .page-header>.inner {
    // remove header image
    background-image: none;
  }

  .drowl-headroom-wrapper>.region-inner .page-logo {
    padding: 10px 0 0px;
  }

  .navigation-bar-wrapper .menu-block-wrapper > .menu{
    justify-content: space-between;
  }

  .view-km-marken.view-display-id-block_1 .view-content .item-list>ul {
    // align with the last menu item
    @media #{$large-up}{
      justify-content: flex-end;
      padding-right:70px;
    }
  }
  .page-header #block-views-km-marken-block-1 {
    bottom: 34px;
  }

  .view-km-marken.view-display-id-block_1 .views-row>.inner {
    max-width:80px;
  }
}

// =============================================================================
//  Mobules
// =============================================================================

@import 'modules/eu_cookie_compliance';

// Auto uncle widget
// -- mov up a little so it doesnt conflict with the eu cookie compliance revoke button on desktop
//    and the icon bar on mobile
#au-su-container{
  bottom: 95px !important;
  @include media-breakpoint-up(md) {
    bottom: 15px !important;
  }
}
