@charset "UTF-8";
html {
  background-color: #fff; }

body {
  color: #626366;
  overflow-x: hidden;
  background: transparent; }

img {
  width: auto;
  height: auto; }

.page-header {
  background-color: #fff; }
  .page-header > .inner {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; }
    @media (min-width: 768px) {
      .page-header > .inner {
        justify-content: flex-start;
        min-height: 143px;
        background-image: url("../images/silhouette_minden_porta.svg");
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: contain; } }
    @media (min-width: 992px) {
      .page-header > .inner {
        background-size: 69%;
        background-position: right 0 bottom 3px; } }
    .page-header > .inner::after, .page-header > .inner::before {
      display: none !important; }
  .page-header #block-views-km-marken-block-1 {
    width: 100%;
    text-align: center; }
    @media (min-width: 768px) {
      .page-header #block-views-km-marken-block-1 {
        position: absolute;
        bottom: 2px;
        right: 0; } }
    @media (min-width: 992px) {
      .page-header #block-views-km-marken-block-1 {
        right: 0;
        width: 69%; } }

.front .page-columns-outer {
  background-position: center center;
  background-size: cover;
  padding-top: 0; }

.front .page-content .region-wrapper--middle {
  background: #d8d9da;
  position: relative;
  left: 50%;
  width: 100vw;
  margin-left: -50vw;
  margin-bottom: 30px;
  padding-top: 30px; }
  .front .page-content .region-wrapper--middle .region-wrapper-inner {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto; }
  .front .page-content .region-wrapper--middle::after {
    height: 1px;
    background: #e5e6e6;
    visibility: visible;
    display: block;
    position: relative;
    left: 50%;
    width: 100vw;
    margin-left: -50vw; }

.page-footer {
  background: #626366;
  background-image: url("images/stripe_bg_dark.png");
  background-repeat: no-repeat;
  background-position: right 0 top 0;
  background-size: cover;
  color: #fff; }
  .page-footer > .inner {
    background-color: transparent; }
  .page-footer a,
  .page-footer h1,
  .page-footer h2,
  .page-footer h3,
  .page-footer h4,
  .page-footer h5 {
    color: #fff; }
  .page-footer .menu a {
    color: #d8d9da;
    padding-left: 0;
    padding-right: 0; }
    .page-footer .menu a:hover {
      color: #fff; }

.branding {
  display: none;
  max-width: 265px;
  width: 100%;
  margin: 0; }
  .branding div,
  .branding a {
    display: block;
    width: 100%; }
  @media (min-width: 992px) {
    .branding {
      display: flex;
      align-items: center; }
      .branding::after, .branding::before {
        display: none !important; } }

.page-logo {
  padding: 0;
  text-align: center; }
  @media (min-width: 992px) {
    .page-logo:not(.page-logo--headroom) {
      line-height: 113px; }
      .page-logo:not(.page-logo--headroom) img {
        vertical-align: middle; } }
  .page-logo a {
    display: block; }

.page-name {
  margin: 0; }

.page-slogan {
  margin: 0; }

*,
*::after,
*::before {
  transition-property: color, background-color, border-color;
  transition-duration: 300ms; }

@keyframes pulsating-glow {
  0% {
    box-shadow: 0 0 3px #d9534f; }
  50% {
    box-shadow: 0 0 10px #d9534f; }
  100% {
    box-shadow: 0 0 3px #d9534f; } }

@keyframes dragging-complete {
  0% {
    background: #5bc0de; }
  100% {
    background: #fff; } }

@keyframes swipe-in {
  0% {
    transform: translate(-150px, 0); }
  100% {
    transform: translate(0, 0); } }

@media (min-width: 992px) {
  .region-header {
    margin-left: auto; } }

.region-header > .region-inner::after, .region-header > .region-inner::before {
  display: none !important; }

.region-header .menu {
  width: auto; }
  .region-header .menu::after {
    clear: both;
    content: " ";
    display: table;
    visibility: hidden; }
  .region-header .menu > li {
    display: block;
    float: left; }
  .region-header .menu li {
    float: none;
    display: inline-block;
    margin-right: 15px; }
    .region-header .menu li:last-child {
      margin-right: 0; }
    .region-header .menu li a {
      padding: 2px 0; }

.region-header .block-search {
  margin-bottom: 0;
  margin-top: 15px; }
  @media (min-width: 768px) {
    .region-header .block-search {
      float: right; } }

.region-header-slide {
  clear: both; }

.navigation-bar-wrapper {
  clear: both;
  background: #fff;
  border-top: 1px solid #d8d9da; }
  .navigation-bar-wrapper > .region-inner {
    max-width: 1170px;
    margin: 0 auto; }

.sidebar .block-title {
  text-align: center; }

@media (min-width: 992px) {
  .sidebar-second .navigation-bar-wrapper > .inner {
    position: relative; }
    .sidebar-second .navigation-bar-wrapper > .inner::before {
      content: " ";
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIzNjhweCIgaGVpZ2h0PSI0NTZweCIgdmlld0JveD0iMCAwIDM2OCA0NTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM2OCA0NTYiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwb2x5Z29uIGZpbGw9IiM2MjYzNjYiIHBvaW50cz0iMjkuOCwwIDAsMCAwLDg2IDM1Ny41LDQ1NiAzNjgsNDU2IDM2OCwxODUgIi8+PC9zdmc+");
      background-repeat: no-repeat;
      background-color: #fff;
      background-size: 33px 42px;
      height: 42px;
      width: 33.3333%;
      box-shadow: 300px 0 0 #fff, 600px 0 0 #fff, 900px 0 0 #fff;
      display: block;
      position: absolute;
      right: 25px;
      bottom: -42px;
      z-index: 2; }
    .sidebar-second .navigation-bar-wrapper > .inner::after {
      content: " ";
      background-color: #626366;
      box-shadow: 300px 0 0 #626366, 600px 0 0 #626366, 900px 0 0 #626366;
      height: 25px;
      width: calc(33.3333% - 8px);
      display: block;
      position: absolute;
      right: 0;
      bottom: -42px;
      z-index: 3; } }

.sidebar-second .second-sidebar-wrapper {
  padding-top: 20px; }

.not-front .navigation-bar-wrapper {
  border-bottom: 8px solid #626366; }

.flexbox .region-navigation-bar > .region-inner::after,
.flexboxlegacy .region-navigation-bar > .region-inner::after {
  display: none; }

.region-help .block {
  border-width: 1px;
  border-style: solid;
  padding: 25px 30px;
  position: relative;
  font-size: 12px;
  padding-left: 60px;
  line-height: 1.1em;
  border-color: #39b3d7;
  text-shadow: 1px 1px 1px #39b3d7;
  background-color: #5bc0de;
  color: #fff;
  cursor: help; }
  .region-help .block::before {
    font-family: "iconfont";
    content: ""; }
  .region-help .block::before {
    text-shadow: none;
    font-family: "iconfont";
    color: #39b3d7;
    background-color: #fff;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    vertical-align: middle;
    position: absolute;
    left: 15px;
    top: 13px;
    font-size: 22px; }
  .region-help .block:before, .region-help .block:after {
    display: table;
    content: " "; }
  .region-help .block:after {
    clear: both; }

.region-bottom {
  border-top: 1px solid #d8d9da;
  padding-top: 6em;
  margin-top: 25px; }

.region-footer > .region-inner {
  padding: 30px 0 15px; }
  .region-footer > .region-inner::after, .region-footer > .region-inner::before {
    display: none !important; }

.region-footer-bottom {
  border-top: 1px solid #76777b;
  background: #626366;
  color: #fff; }
  @media (max-width: 1419px) {
    .region-footer-bottom {
      padding-bottom: 62px; } }
  .region-footer-bottom a {
    color: #fff; }

.block-menu-menu-footer-menu .menu {
  text-align: center; }
  .block-menu-menu-footer-menu .menu li {
    display: block; }
    @media (min-width: 992px) {
      .block-menu-menu-footer-menu .menu li {
        display: inline-block; } }
    .block-menu-menu-footer-menu .menu li a {
      color: #e5e6e6;
      padding-top: 15px;
      padding-bottom: 15px; }
      @media (min-width: 992px) {
        .block-menu-menu-footer-menu .menu li a {
          padding-top: 30px;
          padding-bottom: 30px; } }
      .block-menu-menu-footer-menu .menu li a:hover {
        color: #fff; }

.block-title {
  margin-top: 0; }

.block-content p:last-child {
  margin-bottom: 0; }

.panel-pane {
  margin-bottom: 30px; }
  .panel-pane .pane-title {
    margin-top: 0; }
  .panel-pane .pane-content p:last-child {
    margin-bottom: 0; }

.pane--btn {
  height: 100%;
  padding-bottom: 15px;
  margin-bottom: 0; }
  @media (min-width: 992px) {
    .pane--btn {
      padding-bottom: 30px; } }
  .pane--btn .pane-content {
    height: 100%; }

.block--right,
.panel-pane--right {
  margin-left: auto;
  margin-right: 3px;
  float: right; }

.stripe-title-wrapper {
  max-width: 100%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
  z-index: 2;
  margin-bottom: -15px; }
  .stripe-title-wrapper::after {
    clear: both;
    content: " ";
    display: table;
    visibility: hidden; }
  @media (min-width: 992px) {
    .stripe-title-wrapper {
      max-width: 1170px;
      overflow: visible; } }
  .stripe-title-wrapper .block-title {
    color: #fff;
    margin: 0 -30px;
    display: flex;
    align-items: flex-start; }
    @media (min-width: 992px) {
      .stripe-title-wrapper .block-title {
        margin-left: 0;
        margin-right: 0; } }
    .stripe-title-wrapper .block-title__inner {
      display: block;
      background: #626366;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      float: left;
      order: 0;
      flex-grow: 1;
      text-align: left;
      padding: 0.35em 15px 0.35em 30px;
      font-size: 16px;
      height: 45px;
      line-height: 34px;
      vertical-align: middle; }
      @media (min-width: 544px) {
        .stripe-title-wrapper .block-title__inner {
          font-size: 22px;
          padding-left: 15px;
          padding-right: 15px;
          overflow: visible; } }
    @media (min-width: 992px) {
      .stripe-title-wrapper .block-title::before {
        content: " ";
        background: #626366;
        height: 15px;
        box-shadow: 300px 0 0 #626366, 600px 0 0 #626366;
        width: 100%;
        float: right;
        order: 2;
        flex-shrink: 1;
        align-self: flex-end; } }
    .stripe-title-wrapper .block-title::after {
      content: " ";
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIxNzZweCIgaGVpZ2h0PSIyMzMuM3B4IiB2aWV3Qm94PSIwIDAgMTc2IDIzMy4zIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxNzYgMjMzLjMiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnIGlkPSJFYmVuZV8yIj48cG9seWdvbiBmaWxsPSIjNjI2MzY2IiBwb2ludHM9IjE3NiwyMzMuNCAxMDIsMjMzLjQgMCwxNzMuNzAxIDAsLTEuMyAxNzYsMTczLjcwMSAiLz48L2c+PC9zdmc+");
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      height: 59px;
      width: 45px;
      margin-left: -1px;
      margin-right: -1px;
      min-width: 45px;
      min-height: 59px;
      float: right;
      order: 1; }

.views-field-field-headerimage {
  line-height: 0; }

.block-views-header-slideshow-block {
  line-height: 0;
  display: none; }
  @media (min-width: 768px) {
    .block-views-header-slideshow-block {
      display: block; } }

.view-header-slideshow {
  position: relative; }
  .view-header-slideshow img {
    width: 100% !important;
    min-height: 100px;
    max-height: 80vh;
    max-width: none;
    object-fit: cover;
    object-position: 50% 68%; }
  .view-header-slideshow div[class*="item-"] {
    display: none; }
  .view-header-slideshow div.item-0 {
    display: block; }
  .view-header-slideshow .owl-item div[class*="item-"] {
    display: flex;
    flex-direction: column;
    height: 100%; }
    @media (min-width: 992px) {
      .view-header-slideshow .owl-item div[class*="item-"] {
        display: block; } }
  .view-header-slideshow .view-empty {
    padding: 0;
    border: 0 none;
    border-radius: 0; }
  @media (max-width: 1169px) {
    .view-header-slideshow .owl-dots {
      display: none !important; } }

.header-image-overlay {
  background: none;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  text-align: center;
  flex: 1; }
  @media (min-width: 992px) {
    .header-image-overlay {
      align-items: center;
      justify-content: center;
      background-image: none; } }
  .header-image-overlay__box {
    padding: 15px 30px;
    width: 100%; }
    @media (min-width: 992px) {
      .header-image-overlay__box {
        text-align: center;
        font-size: 30px;
        line-height: 1.35;
        padding: 15px 15px;
        margin-left: 60px;
        margin-right: 60px;
        margin-top: auto;
        margin-bottom: 60px; } }
  .header-image-overlay__content {
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.36); }
    @media (max-width: 1169px) {
      .header-image-overlay__content h2 {
        font-size: 1rem; } }
    .header-image-overlay__content h2,
    .header-image-overlay__content h3 {
      color: #fff; }
    .header-image-overlay__content .field-name-body {
      border: 0 none !important;
      margin: 0 !important; }
      @media (max-width: 1169px) {
        .header-image-overlay__content .field-name-body {
          line-height: 1.1; } }
    .header-image-overlay__content .btn {
      padding: 18.75px 25px;
      border-radius: 0;
      line-height: 18px;
      font-size: 16px;
      text-transform: uppercase; }
      @media (max-width: 991px) {
        .header-image-overlay__content .btn {
          background-color: transparent;
          border-color: #4e4f51;
          color: #fff; }
          .header-image-overlay__content .btn:hover {
            background-color: transparent;
            border-color: #5b5b5e;
            color: #fff; }
          .header-image-overlay__content .btn:active, .header-image-overlay__content .btn:focus {
            background-color: transparent;
            border-color: #424244;
            color: #fff; } }
    .header-image-overlay__content p {
      margin-top: 0; }
  .header-image-overlay__title {
    margin-top: 0;
    margin-bottom: .25em;
    color: #fff;
    font-weight: 700; }
    @media (min-width: 992px) {
      .header-image-overlay__title {
        font-size: 60px;
        line-height: 1.15; } }
  .header-image-overlay--has-text {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.45) 100%); }
    @media (min-width: 992px) {
      .header-image-overlay--has-text {
        background-image: none; } }
  .header-image-overlay--left {
    justify-content: flex-start;
    text-align: left; }
    @media (min-width: 992px) {
      .header-image-overlay--left .header-image-overlay__box {
        max-width: 500px; } }
    @media (min-width: 1170px) {
      .header-image-overlay--left .header-image-overlay__box {
        max-width: 50vw; } }
  .header-image-overlay--right {
    justify-content: flex-end;
    text-align: right; }
    @media (min-width: 992px) {
      .header-image-overlay--right .header-image-overlay__box {
        right: 15px;
        text-align: right;
        max-width: 500px; } }
    @media (min-width: 1170px) {
      .header-image-overlay--right .header-image-overlay__box {
        max-width: 50vw;
        margin-right: 90px; } }
  @media (min-width: 992px) {
    .header-image-overlay--invert-text {
      color: #626366 !important;
      text-shadow: none !important; }
      .header-image-overlay--invert-text * {
        color: #626366 !important;
        text-shadow: none !important; } }

.header-image__overlay-link {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  text-indent: -9000px;
  text-align: left; }

.header-image--matching-images {
  border-width: 1px;
  border-style: solid;
  padding: 25px 30px;
  border-color: #e5e6e6;
  border-radius: 8px;
  background-color: #fff; }
  .header-image--matching-images:before, .header-image--matching-images:after {
    display: table;
    content: " "; }
  .header-image--matching-images:after {
    clear: both; }

.block-menu-menu-call-to-action-quicklinks {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 98;
  bottom: 32px;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #4e4f51; }
  @media (min-width: 544px) {
    .block-menu-menu-call-to-action-quicklinks {
      bottom: 0;
      border-bottom: 0 none; } }
  @media (min-width: 1170px) {
    .block-menu-menu-call-to-action-quicklinks {
      bottom: auto;
      right: 0;
      top: 50%;
      left: auto;
      width: auto;
      margin-top: -75px; } }
  .block-menu-menu-call-to-action-quicklinks .block-content {
    background: #626366;
    color: #fff;
    border-color: #e5e6e6;
    border-width: 1px 0 0;
    border-style: solid;
    padding: 15px 15px;
    width: 100%;
    min-width: 200px; }
    @media (min-width: 544px) {
      .block-menu-menu-call-to-action-quicklinks .block-content {
        width: auto;
        border-width: 1px 1px 0; } }
    @media (min-width: 1170px) {
      .block-menu-menu-call-to-action-quicklinks .block-content {
        min-width: 0;
        padding: 25px 15px;
        border-width: 0; } }
  .block-menu-menu-call-to-action-quicklinks .menu {
    display: flex;
    justify-content: space-between; }
    .block-menu-menu-call-to-action-quicklinks .menu::after {
      display: none !important; }
    .block-menu-menu-call-to-action-quicklinks .menu li.last a {
      margin-bottom: 0; }
    .block-menu-menu-call-to-action-quicklinks .menu a {
      color: #fff; }
      .block-menu-menu-call-to-action-quicklinks .menu a.ico-seperate {
        width: 42px; }
      @media (min-width: 1170px) {
        .block-menu-menu-call-to-action-quicklinks .menu a {
          margin-right: 0;
          margin-bottom: 25px; } }
    @media (min-width: 1170px) {
      .block-menu-menu-call-to-action-quicklinks .menu {
        flex-direction: column; } }

@media (min-width: 1170px) {
  .front .block-menu-menu-call-to-action-quicklinks {
    bottom: 120px;
    top: auto;
    margin-top: 0; } }

@media (min-width: 992px) {
  #block-panels-mini-cta-below-content {
    margin-bottom: 15px; } }

#block-panels-mini-cta-below-content .block-content {
  background-image: url("images/pixabay_mountain_road_1556177.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 60px 0 30px; }
  #block-panels-mini-cta-below-content .block-content a:not(.btn),
  #block-panels-mini-cta-below-content .block-content h3 {
    color: #fff;
    text-decoration: none; }

#block-panels-mini-cta-below-content .panel-display {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px; }

#block-panels-mini-cta-below-content .region .pane-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; }
  #block-panels-mini-cta-below-content .region .pane-content .btn {
    transition-property: transform;
    transition-timing-function: linear; }
  #block-panels-mini-cta-below-content .region .pane-content > a:last-child {
    color: rgba(255, 255, 255, 0.6); }
  #block-panels-mini-cta-below-content .region .pane-content:hover .btn {
    transform: scale(1.1); }
  #block-panels-mini-cta-below-content .region .pane-content:hover > a:last-child {
    color: #fff; }

#block-panels-mini-cta-below-content .region h3 {
  margin-bottom: 0; }

#block-panels-mini-footer-map-overlay,
#block-panels-mini-lp-wkda-footer-map-overlay {
  position: relative;
  margin-bottom: 0;
  overflow: hidden; }
  @media (max-width: 1419px) {
    #block-panels-mini-footer-map-overlay,
    #block-panels-mini-lp-wkda-footer-map-overlay {
      margin-bottom: 2em; } }
  @media (min-width: 1170px) {
    #block-panels-mini-footer-map-overlay,
    #block-panels-mini-lp-wkda-footer-map-overlay {
      padding: 6em 0; } }
  @media (min-width: 1170px) {
    #block-panels-mini-footer-map-overlay .block-content,
    #block-panels-mini-lp-wkda-footer-map-overlay .block-content {
      max-width: 1170px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 30px;
      padding-right: 30px; } }
  #block-panels-mini-footer-map-overlay .region-two-50-top > .region-inner > .panel-pane,
  #block-panels-mini-lp-wkda-footer-map-overlay .region-two-50-top > .region-inner > .panel-pane {
    margin-bottom: 0; }
  #block-panels-mini-footer-map-overlay .region-two-50-top + .region-cols,
  #block-panels-mini-lp-wkda-footer-map-overlay .region-two-50-top + .region-cols {
    padding: 30px;
    background: #626366;
    color: #fff;
    position: relative;
    z-index: 2;
    margin-left: 0;
    margin-right: 0; }
    #block-panels-mini-footer-map-overlay .region-two-50-top + .region-cols:hover,
    #block-panels-mini-lp-wkda-footer-map-overlay .region-two-50-top + .region-cols:hover {
      background: #626366; }
    @media (min-width: 1170px) {
      #block-panels-mini-footer-map-overlay .region-two-50-top + .region-cols,
      #block-panels-mini-lp-wkda-footer-map-overlay .region-two-50-top + .region-cols {
        padding: 30px 60px;
        max-width: 55%;
        margin-left: auto;
        display: flex;
        background: rgba(98, 99, 102, 0.85); } }
  #block-panels-mini-footer-map-overlay .panel-display a,
  #block-panels-mini-footer-map-overlay .panel-display h2,
  #block-panels-mini-footer-map-overlay .panel-display h3,
  #block-panels-mini-footer-map-overlay .panel-display h4,
  #block-panels-mini-lp-wkda-footer-map-overlay .panel-display a,
  #block-panels-mini-lp-wkda-footer-map-overlay .panel-display h2,
  #block-panels-mini-lp-wkda-footer-map-overlay .panel-display h3,
  #block-panels-mini-lp-wkda-footer-map-overlay .panel-display h4 {
    color: #fff; }
  @media (min-width: 1170px) {
    #block-panels-mini-footer-map-overlay .panel-display .panel-pane,
    #block-panels-mini-lp-wkda-footer-map-overlay .panel-display .panel-pane {
      margin-bottom: 0; } }
  #block-panels-mini-footer-map-overlay .panel-display .pane-title,
  #block-panels-mini-lp-wkda-footer-map-overlay .panel-display .pane-title {
    font-size: 22px; }
  #block-panels-mini-footer-map-overlay .panel-display h3,
  #block-panels-mini-lp-wkda-footer-map-overlay .panel-display h3 {
    font-size: 16px;
    margin-top: 0; }
  #block-panels-mini-footer-map-overlay .panel-display .btn,
  #block-panels-mini-lp-wkda-footer-map-overlay .panel-display .btn {
    border-color: #fff;
    background-color: #fff;
    color: #626366; }
    #block-panels-mini-footer-map-overlay .panel-display .btn:hover,
    #block-panels-mini-lp-wkda-footer-map-overlay .panel-display .btn:hover {
      border-color: #fff;
      background-color: #fff;
      color: #6e7073; }
    #block-panels-mini-footer-map-overlay .panel-display .btn:active, #block-panels-mini-footer-map-overlay .panel-display .btn:focus,
    #block-panels-mini-lp-wkda-footer-map-overlay .panel-display .btn:active,
    #block-panels-mini-lp-wkda-footer-map-overlay .panel-display .btn:focus {
      border-color: #fff;
      background-color: #fff;
      color: #565659; }
  #block-panels-mini-footer-map-overlay .panel-display .opening-hours,
  #block-panels-mini-lp-wkda-footer-map-overlay .panel-display .opening-hours {
    margin-bottom: 1em;
    display: flex;
    flex-wrap: wrap; }
    #block-panels-mini-footer-map-overlay .panel-display .opening-hours .days,
    #block-panels-mini-lp-wkda-footer-map-overlay .panel-display .opening-hours .days {
      width: 40%; }
    #block-panels-mini-footer-map-overlay .panel-display .opening-hours .time,
    #block-panels-mini-lp-wkda-footer-map-overlay .panel-display .opening-hours .time {
      width: 60%;
      white-space: nowrap; }
  #block-panels-mini-footer-map-overlay .contact-container .item:nth-of-type(4),
  #block-panels-mini-footer-map-overlay strong[itemprop="name"],
  #block-panels-mini-lp-wkda-footer-map-overlay .contact-container .item:nth-of-type(4),
  #block-panels-mini-lp-wkda-footer-map-overlay strong[itemprop="name"] {
    display: none; }
  #block-panels-mini-footer-map-overlay .pane--map,
  #block-panels-mini-lp-wkda-footer-map-overlay .pane--map {
    position: relative !important;
    margin: -30px -30px 30px; }
    @media (min-width: 1170px) {
      #block-panels-mini-footer-map-overlay .pane--map,
      #block-panels-mini-lp-wkda-footer-map-overlay .pane--map {
        margin: 0 0 30px;
        position: static !important; } }
    #block-panels-mini-footer-map-overlay .pane--map .contextual-links-wrapper,
    #block-panels-mini-lp-wkda-footer-map-overlay .pane--map .contextual-links-wrapper {
      display: none !important; }
  #block-panels-mini-footer-map-overlay .btn--map-expand,
  #block-panels-mini-lp-wkda-footer-map-overlay .btn--map-expand {
    position: absolute;
    left: 15px;
    bottom: 15px; }
  #block-panels-mini-footer-map-overlay .image-map,
  #block-panels-mini-lp-wkda-footer-map-overlay .image-map {
    min-width: 100%;
    display: block; }
    @media (min-width: 1170px) {
      #block-panels-mini-footer-map-overlay .image-map,
      #block-panels-mini-lp-wkda-footer-map-overlay .image-map {
        position: absolute;
        left: -500px;
        top: 0;
        width: calc(100vw + 500px);
        height: 100%;
        max-width: none;
        z-index: 1; } }
  #block-panels-mini-footer-map-overlay .contextual-links-region,
  #block-panels-mini-lp-wkda-footer-map-overlay .contextual-links-region {
    position: static; }
    #block-panels-mini-footer-map-overlay .contextual-links-region .contextual-links-wrapper,
    #block-panels-mini-lp-wkda-footer-map-overlay .contextual-links-region .contextual-links-wrapper {
      display: none !important; }

#block-panels-mini-lp-wkda-footer-map-overlay .region-two-50-top + .region-cols .region {
  float: none !important;
  width: auto !important;
  padding-left: 0 !important;
  padding-right: 0 !important; }

@media (min-width: 1170px) {
  #block-panels-mini-lp-wkda-footer-map-overlay .region-two-50-top + .region-cols {
    max-width: 430px; } }

.view-km-ansprechpartner .view-grouping-content > h2 {
  display: none; }

.view-km-ansprechpartner-er.view-display-id-block_1 .media-object {
  background: #626366;
  color: #fff;
  display: table;
  width: 100%;
  display: flex;
  flex-direction: column; }
  @media (min-width: 544px) {
    .view-km-ansprechpartner-er.view-display-id-block_1 .media-object {
      flex-direction: row; } }

@media (min-width: 768px) and (max-width: 1169px) {
  .view-km-ansprechpartner-er.view-display-id-block_1 .media-object {
    flex-direction: column; } }
  .view-km-ansprechpartner-er.view-display-id-block_1 .media-object__subline {
    font-size: 12px;
    font-style: italic;
    color: #e5e6e6; }
  .view-km-ansprechpartner-er.view-display-id-block_1 .media-object__content {
    padding: 15px 15px 15px 15px;
    display: flex;
    flex-direction: column;
    flex: 1; }
  .view-km-ansprechpartner-er.view-display-id-block_1 .media-object__image {
    padding-bottom: 0; }
    .view-km-ansprechpartner-er.view-display-id-block_1 .media-object__image img {
      max-width: none;
      width: 100%;
      height: 100%; }
    @media (min-width: 544px) {
      .view-km-ansprechpartner-er.view-display-id-block_1 .media-object__image {
        min-width: 100px;
        max-width: 120px; } }

@media (min-width: 768px) and (max-width: 1169px) {
  .view-km-ansprechpartner-er.view-display-id-block_1 .media-object__image {
    max-width: 100%;
    width: 100%; }
    .view-km-ansprechpartner-er.view-display-id-block_1 .media-object__image img {
      max-width: none;
      width: 100%; } }
    @media (min-width: 1170px) {
      .view-km-ansprechpartner-er.view-display-id-block_1 .media-object__image {
        max-width: 160px;
        min-width: 120px;
        max-height: 200px; } }
    .view-km-ansprechpartner-er.view-display-id-block_1 .media-object__image * {
      height: 100%; }
    .view-km-ansprechpartner-er.view-display-id-block_1 .media-object__image img {
      object-fit: cover; }
  .view-km-ansprechpartner-er.view-display-id-block_1 .media-object__further-fields {
    margin-top: auto; }
  .view-km-ansprechpartner-er.view-display-id-block_1 .media-object a,
  .view-km-ansprechpartner-er.view-display-id-block_1 .media-object h2,
  .view-km-ansprechpartner-er.view-display-id-block_1 .media-object h3 {
    color: #fff; }
  .view-km-ansprechpartner-er.view-display-id-block_1 .media-object h2,
  .view-km-ansprechpartner-er.view-display-id-block_1 .media-object h3 {
    font-size: 1em; }

.field-label {
  font-size: 1em;
  font-weight: 400;
  font-family: inherit;
  line-height: inherit;
  margin-bottom: 0; }

.field-label-inline .field-item,
.field-label-inline .field-items,
.field-label-inline .field-label {
  display: inline; }

a.colorbox {
  cursor: zoom-in; }

.field-name-body::after {
  clear: both;
  content: " ";
  display: table;
  visibility: hidden; }

.field-name-body h2:first-child,
.field-name-body h3:first-child {
  margin-top: 0;
  padding-top: 0; }

.field-type-image .colorbox {
  cursor: zoom-in; }

.field-type-image .field-item {
  line-height: 0;
  text-align: center; }

.field-name-field-titelbild {
  margin-bottom: 25px; }

.field-name-field-galerie {
  margin-top: 25px; }
  .field-name-field-galerie .field-item {
    margin-bottom: 30px; }
    .field-name-field-galerie .field-item a {
      overflow: hidden;
      display: block; }
    .field-name-field-galerie .field-item img {
      transition-property: transform, opacity; }
    .field-name-field-galerie .field-item:hover img {
      transform: scale(1.05); }

.field-type-video-embed-field .field-item {
  margin-bottom: 15px; }
  .field-type-video-embed-field .field-item .colorbox {
    line-height: 0; }

.field-name-field-tags {
  margin-bottom: 1em; }

.field-name-field-attachments {
  margin-bottom: 1.5em; }
  .field-name-field-attachments .field-items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px; }
  @media (min-width: 768px) {
    .field-name-field-attachments .field-item {
      width: 50%;
      padding: 0 15px; } }
  .field-name-field-attachments .file {
    display: block;
    padding: 4px 0;
    margin-bottom: 3px; }
    .field-name-field-attachments .file:not(:last-child) {
      border-bottom: 1px solid #d8d9da; }
    .field-name-field-attachments .file a {
      border-width: 1px;
      border-style: solid;
      display: inline-block;
      font-weight: 400;
      cursor: pointer;
      text-align: left;
      text-decoration: none;
      vertical-align: top;
      user-select: none;
      background-color: transparent;
      border-color: #626366;
      color: #626366;
      padding: 15px 20px;
      border-radius: 0;
      line-height: 18px;
      font-size: 16px;
      line-height: 1.05em;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block; }
      .field-name-field-attachments .file a:hover {
        background-color: transparent;
        border-color: #76777b;
        color: #626366; }
      .field-name-field-attachments .file a:active, .field-name-field-attachments .file a:focus {
        background-color: transparent;
        border-color: #565659;
        color: #626366; }
      .field-name-field-attachments .file a:hover {
        text-decoration: none; }

.field-name-field-email,
.field-name-field-fon {
  white-space: nowrap; }

.field-name-field-further-description {
  border-top: 1px solid #d8d9da;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
  font-size: 12px; }

.custom-search-filter ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.custom-search-filter li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block; }

.custom-search-filter a {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-weight: 400;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  background-color: transparent;
  border-color: #626366;
  color: #626366;
  padding: 15px 20px;
  border-radius: 0;
  line-height: 18px;
  font-size: 16px;
  line-height: 1.05em; }
  .custom-search-filter a:hover {
    background-color: transparent;
    border-color: #76777b;
    color: #626366; }
  .custom-search-filter a:active, .custom-search-filter a:focus {
    background-color: transparent;
    border-color: #565659;
    color: #626366; }
  .custom-search-filter a:hover {
    text-decoration: none; }

.node {
  margin-bottom: 20px; }
  .node__header {
    text-align: center; }
    .node__header .page-title {
      display: inline-block;
      line-height: 1.35; }
    .node__header .field-name-field-subtitle {
      display: inline-block;
      font-size: 32px;
      line-height: 1.35;
      text-transform: uppercase;
      margin-left: 15px; }
    .node__header--has-subline {
      margin-bottom: 22px; }
  .node .node-title {
    margin: 0; }
  .node.node-header-image {
    margin-bottom: 0; }
  .node.view-mode-full .field-name-body {
    margin-bottom: 30px; }

footer.submitted {
  background: none repeat scroll 0 0 #e5e6e6;
  clear: both;
  color: #d8d9da;
  margin-top: 1em;
  padding: 10px 10px 10px 15px;
  vertical-align: top; }
  footer.submitted .user-picture {
    border: 5px solid #e5e6e6;
    background: #fff;
    float: left;
    line-height: 0;
    margin-right: 15px;
    margin-left: -15px;
    margin-top: -10px; }

.node-ansprechpartner .field-name-field-fon .field-label {
  text-indent: -9000px;
  text-align: left;
  display: inline-block;
  float: left;
  margin-right: 4px;
  font-size: 0;
  width: 18px; }
  .node-ansprechpartner .field-name-field-fon .field-label::before {
    text-indent: 0;
    font-family: "iconfont";
    float: right;
    text-align: center;
    width: 100%;
    content: "";
    font-size: 18px; }
  .node-ansprechpartner .field-name-field-fon .field-label::after {
    clear: both;
    content: " ";
    display: table;
    visibility: hidden; }

.node-ansprechpartner .field-name-field-email .field-label {
  text-indent: -9000px;
  text-align: left;
  display: inline-block;
  float: left;
  margin-right: 4px;
  font-size: 0;
  width: 18px; }
  .node-ansprechpartner .field-name-field-email .field-label::before {
    text-indent: 0;
    font-family: "iconfont";
    float: right;
    text-align: center;
    width: 100%;
    content: "";
    font-size: 18px; }
  .node-ansprechpartner .field-name-field-email .field-label::after {
    clear: both;
    content: " ";
    display: table;
    visibility: hidden; }

.contact-container .item-content {
  line-height: 20px;
  vertical-align: middle; }

.contact-container .item {
  display: block;
  margin-bottom: .4em; }

.contact-container br {
  display: none; }

.view-empty {
  border-width: 1px;
  border-style: solid;
  padding: 25px 30px;
  border-color: #e5e6e6;
  border-radius: 8px;
  background-color: #fff;
  font-size: .9em;
  padding: 2em;
  text-align: center; }
  .view-empty:before, .view-empty:after {
    display: table;
    content: " "; }
  .view-empty:after {
    clear: both; }

.view-header {
  margin-bottom: 25px; }

.view-footer {
  margin-top: 25px; }

.attachment-before {
  margin-bottom: 25px; }

.attachment-after {
  margin-top: 25px; }

.view-content > h3,
.view-content > h2,
.view-content > .item-list > h3,
.view-content > .item-list > h2,
.view-grouping-header > h3,
.view-grouping-header > h2 {
  text-align: center;
  border-top: 1px solid #d8d9da;
  padding-top: 25px;
  margin-top: 25px;
  border-bottom: 1px solid #d8d9da;
  padding-bottom: 25px;
  margin-bottom: 25px; }

.views-row {
  margin-bottom: 25px; }
  .views-row::after {
    clear: both;
    content: " ";
    display: table;
    visibility: hidden; }
  .views-row.cutline-bottom:last-child {
    border-bottom: 0 none;
    margin-bottom: 0;
    padding-bottom: 0; }

.view .item-list > ul {
  margin: 0;
  padding: 0; }
  .view .item-list > ul > .views-row {
    list-style: none; }

.view-filters {
  border-bottom: 1px solid #d8d9da;
  padding-bottom: 25px;
  margin-bottom: 25px; }

.views-exposed-form__button-wrapper {
  display: flex;
  justify-content: space-between; }

.views-exposed-form .ctools-auto-submit-full-form .views-submit-button {
  display: none; }

.views-exposed-widgets {
  display: flex;
  align-items: flex-end; }

.views-exposed-widget {
  margin-bottom: 15px; }
  .views-exposed-widget.views-widget-filter-date_filter > label {
    display: none; }
  .views-exposed-widget .date-padding {
    display: block; }
    .views-exposed-widget .date-padding .form-item {
      margin: 0; }

.views-submit-button {
  margin-left: auto; }

.view-image {
  text-align: center; }

.more-link,
.read-on {
  text-align: right;
  display: block;
  clear: both; }

.more-link a,
.read-on a,
a.more-link {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-weight: 400;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  background-color: transparent;
  border-color: #626366;
  color: #626366;
  padding: 15px 20px;
  border-radius: 0;
  line-height: 18px;
  font-size: 16px;
  line-height: 1.05em; }
  .more-link a:hover,
  .read-on a:hover,
  a.more-link:hover {
    background-color: transparent;
    border-color: #76777b;
    color: #626366; }
  .more-link a:active, .more-link a:focus,
  .read-on a:active,
  .read-on a:focus,
  a.more-link:active,
  a.more-link:focus {
    background-color: transparent;
    border-color: #565659;
    color: #626366; }
  .more-link a::before,
  .read-on a::before,
  a.more-link::before {
    line-height: 1;
    display: inline-block;
    font-family: "iconfont";
    font-style: normal;
    font-weight: normal;
    margin-right: 5px;
    text-align: center;
    content: ""; }
  .more-link a:hover,
  .read-on a:hover,
  a.more-link:hover {
    text-decoration: none; }

a.more-link {
  float: right;
  margin-top: 15px; }

td.read-on a {
  display: block;
  text-align: left;
  margin-bottom: .25em; }

.view-km-marken.view-display-id-block_1 {
  line-height: 1; }
  .view-km-marken.view-display-id-block_1 .views-row {
    margin-bottom: 0; }
    .view-km-marken.view-display-id-block_1 .views-row::after {
      display: none; }
    .view-km-marken.view-display-id-block_1 .views-row:hover {
      transform: scale(1.1); }
    .view-km-marken.view-display-id-block_1 .views-row:not(.views-row-1) {
      padding-left: 15px; }
    .view-km-marken.view-display-id-block_1 .views-row > .inner {
      max-width: 43px; }
      .view-km-marken.view-display-id-block_1 .views-row > .inner a {
        display: block; }
      .view-km-marken.view-display-id-block_1 .views-row > .inner img {
        max-height: 43px; }
  .view-km-marken.view-display-id-block_1 .view-content .item-list > ul {
    display: flex;
    align-items: center;
    justify-content: center; }

.view-id-glossary .views-row {
  clear: both;
  margin-left: 15px; }

.view-id-glossary .attachment {
  margin-bottom: 1.5em;
  color: #d8d9da; }

.view-id-glossary .views-summary {
  position: relative; }
  .view-id-glossary .views-summary a {
    text-decoration: none;
    padding: 3px 5px;
    display: inline-block;
    min-width: 15px;
    text-align: center; }
  .view-id-glossary .views-summary .count {
    display: none; }
  .view-id-glossary .views-summary:hover .count {
    position: absolute;
    bottom: 17px;
    right: -2px;
    padding: 2px;
    border-radius: 8px;
    font-size: .65em;
    line-height: normal;
    display: inline; }
  .view-id-glossary .views-summary:hover a,
  .view-id-glossary .views-summary a.active {
    border-radius: 5px;
    margin-top: -3px;
    background: #626366;
    color: #fff; }
  .view-id-glossary .views-summary .view-content a {
    display: inline-block; }
    .view-id-glossary .views-summary .view-content a::first-letter {
      font-size: 1.2em;
      color: #626366; }

.view-km-historie .item-list {
  padding: 0;
  margin: 0; }
  .view-km-historie .item-list ol {
    margin: 0;
    padding: 0; }
  .view-km-historie .item-list .views-row {
    list-style: none;
    clear: both;
    margin: 0 0 15px;
    padding: 0; }
    .view-km-historie .item-list .views-row .media-object {
      background: #e5e6e6;
      border-top: 5px solid #626366;
      padding: 10px;
      min-height: 55px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box; }
      .view-km-historie .item-list .views-row .media-object__image {
        background: transparent; }
      @media (min-width: 992px) {
        .view-km-historie .item-list .views-row .media-object {
          width: 75%; } }
      .view-km-historie .item-list .views-row .media-object p {
        margin: 0; }
    .view-km-historie .item-list .views-row h2 {
      font-size: 1.2em;
      line-height: normal;
      margin: 0 0 .35em;
      -webkit-transition-duration: 200ms;
      transition-duration: 200ms; }
    .view-km-historie .item-list .views-row .views-field-field-jahr {
      font-weight: 700;
      text-align: center;
      line-height: 50px;
      vertical-align: middle;
      height: 50px;
      position: relative;
      background: #636466;
      color: #fff; }
      @media (min-width: 992px) {
        .view-km-historie .item-list .views-row .views-field-field-jahr {
          width: 25%; } }
    .view-km-historie .item-list .views-row:hover h2 {
      color: #626366; }
    .view-km-historie .item-list .views-row:hover .views-field-field-jahr {
      background: #626366; }
    @media (min-width: 992px) {
      .view-km-historie .item-list .views-row.views-row .views-fieldset.inhalt {
        float: left; } }
    @media (min-width: 992px) {
      .view-km-historie .item-list .views-row.views-row .views-field-field-jahr {
        float: left; } }

.page-node-37 .view-km-link-kacheln-er .card__title {
  text-align: center; }

#sufu_daten_wrapper {
  padding-left: 0; }
  @media (min-width: 992px) {
    #sufu_daten_wrapper .col-100 {
      display: flex;
      align-items: center; } }
  @media (max-width: 991px) {
    #sufu_daten_wrapper .col-100 label,
    #sufu_daten_wrapper .col-100 select {
      float: none;
      display: block;
      width: 100%;
      margin: 0 0 5px; } }

.mobilede_infos .buttons {
  margin-bottom: 1em;
  color: transparent; }
  .mobilede_infos .buttons > span {
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    font-weight: 400;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    vertical-align: top;
    user-select: none;
    border-color: #626366;
    background-color: #626366;
    color: #fff;
    padding: 15px 20px;
    border-radius: 0;
    line-height: 18px;
    font-size: 16px;
    line-height: 1.05em;
    margin-right: 4px; }
    .mobilede_infos .buttons > span:hover {
      border-color: #6e7073;
      background-color: #6e7073;
      color: #fff; }
    .mobilede_infos .buttons > span:active, .mobilede_infos .buttons > span:focus {
      border-color: #565659;
      background-color: #565659;
      color: #fff; }
    .mobilede_infos .buttons > span:hover {
      text-decoration: none; }
    .mobilede_infos .buttons > span[onclick^="mobile_de_show_sufu"]::before {
      content: "";
      font-family: "iconfont";
      margin-right: 5px; }
    .mobilede_infos .buttons > span[onclick^="location.reload"]::before {
      content: "";
      font-family: "iconfont";
      margin-right: 5px; }
    @media (max-width: 991px) {
      .mobilede_infos .buttons > span {
        width: 100%;
        margin-bottom: 4px; } }

.mobilede_infos + .clear {
  display: none; }

.car_values3 {
  margin-bottom: .35em; }

#mobilede_sufu_daten > form > .clear {
  display: none; }

#mobilede_sufu_daten > form > div {
  overflow: hidden;
  margin-bottom: 15px; }

#mobilede_sufu_daten > form label {
  font-weight: 700; }

#mobilede_sufu_daten > form select + select {
  margin-left: 15px; }

#orderbyarea {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-weight: 400;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  background-color: transparent;
  border-color: #626366;
  color: #626366;
  padding: 8px 10px;
  border-radius: 0;
  line-height: 1em;
  font-size: 12px;
  line-height: 1.05em;
  margin-bottom: 1em; }
  #orderbyarea:hover {
    background-color: transparent;
    border-color: #76777b;
    color: #626366; }
  #orderbyarea:active, #orderbyarea:focus {
    background-color: transparent;
    border-color: #565659;
    color: #626366; }
  #orderbyarea:hover {
    text-decoration: none; }
  #orderbyarea::before {
    font-family: "iconfont";
    content: "";
    margin-right: 4px; }

.pager_info {
  float: right; }
  .pager_info + .clear {
    display: none; }

#mobile_de_results .control_bottom a {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-weight: 400;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  border-color: #626366;
  background-color: #626366;
  color: #fff;
  padding: 15px 20px;
  border-radius: 0;
  line-height: 18px;
  font-size: 16px;
  line-height: 1.05em; }
  #mobile_de_results .control_bottom a:hover {
    border-color: #6e7073;
    background-color: #6e7073;
    color: #fff; }
  #mobile_de_results .control_bottom a:active, #mobile_de_results .control_bottom a:focus {
    border-color: #565659;
    background-color: #565659;
    color: #fff; }
  #mobile_de_results .control_bottom a:hover {
    text-decoration: none; }

#mobile_de_results .car_img img {
  max-width: 80px; }

#mobile_de_results .car.single_car {
  font-size: 16px;
  padding: 0; }
  #mobile_de_results .car.single_car .back_to_list {
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    font-weight: 400;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    vertical-align: top;
    user-select: none;
    border-color: #626366;
    background-color: #626366;
    color: #fff;
    padding: 15px 20px;
    border-radius: 0;
    line-height: 18px;
    font-size: 16px;
    line-height: 1.05em;
    margin-bottom: 1em; }
    #mobile_de_results .car.single_car .back_to_list:hover {
      border-color: #6e7073;
      background-color: #6e7073;
      color: #fff; }
    #mobile_de_results .car.single_car .back_to_list:active, #mobile_de_results .car.single_car .back_to_list:focus {
      border-color: #565659;
      background-color: #565659;
      color: #fff; }
    #mobile_de_results .car.single_car .back_to_list:hover {
      text-decoration: none; }
    #mobile_de_results .car.single_car .back_to_list::before {
      content: "";
      font-family: "iconfont";
      margin-right: 6px; }
  #mobile_de_results .car.single_car h1 {
    font-size: 22px; }
  #mobile_de_results .car.single_car hr {
    margin: 1.5em 0; }
  #mobile_de_results .car.single_car #mainimg {
    margin-bottom: 15px; }
  #mobile_de_results .car.single_car .row.features {
    margin-left: 0;
    margin-right: 0; }
    @media (max-width: 1169px) {
      #mobile_de_results .car.single_car .row.features {
        column-count: 3; } }
    @media (max-width: 991px) {
      #mobile_de_results .car.single_car .row.features {
        column-count: 2; } }
    @media (max-width: 767px) {
      #mobile_de_results .car.single_car .row.features {
        column-count: 1; } }
  #mobile_de_results .car.single_car .previmages {
    height: auto; }
    #mobile_de_results .car.single_car .previmages img {
      margin-bottom: 12px; }
  #mobile_de_results .car.single_car .au-widget-trade-in {
    margin-top: .5em;
    position: relative;
    left: 13px; }
  #mobile_de_results .car.single_car .col_price .btn {
    display: block;
    margin-bottom: .5rem; }
    #mobile_de_results .car.single_car .col_price .btn + br {
      display: none; }

.financing-calculator {
  border: 1px solid #e5e6e6;
  padding: 20px; }
  .financing-calculator > h2 {
    border-bottom: 1px solid #ff810f;
    margin-top: 0;
    padding-bottom: .35em; }

.single_car_description br + strong {
  display: block;
  margin-top: 2em; }

.btn-wrapper--sharing-options {
  padding: 1em 0; }

.additionalAttributes {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 1em; }
  .additionalAttributes dt {
    width: 60%;
    padding: 8px 12px;
    margin: 0 0 1px;
    background: #626366;
    color: #fff; }
  .additionalAttributes dd {
    width: 40%;
    padding: 8px 12px 0;
    margin: 0 0 1px; }
    .additionalAttributes dd:nth-of-type(2n-1) {
      background-color: #e5e6e6; }

.node-type-landingpage-standalone {
  display: flex;
  flex-direction: column;
  padding-top: 0 !important; }
  .node-type-landingpage-standalone #page {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
  .node-type-landingpage-standalone .page-header {
    background: #626366;
    color: #fff; }
    .node-type-landingpage-standalone .page-header > .inner {
      background: none; }
  .node-type-landingpage-standalone #branding {
    display: block !important;
    max-width: 100%;
    line-height: 0; }
  .node-type-landingpage-standalone .region-header-slide {
    width: 100%;
    margin-bottom: 2.25rem; }
    .node-type-landingpage-standalone .region-header-slide .field-name-field-headerimage > .field-items > .field-item {
      height: 300px;
      width: 100%; }
  @media (min-width: 992px) {
    .node-type-landingpage-standalone .view-header-slideshow img {
      height: 300px;
      object-position: 50% 50%; } }
  .node-type-landingpage-standalone .page-footer {
    margin-top: auto; }

.no-objectfit .node-type-landingpage-standalone .field-name-field-headerimage > .field-items > .field-item img {
  height: auto;
  position: absolute;
  left: 0;
  top: 50%; }

.panel-pane--iframe-wkda {
  margin-bottom: 0; }

.multisite-wisloh .page-header > .inner {
  background-image: none; }

.multisite-wisloh .drowl-headroom-wrapper > .region-inner .page-logo {
  padding: 10px 0 0px; }

.multisite-wisloh .navigation-bar-wrapper .menu-block-wrapper > .menu {
  justify-content: space-between; }

@media only screen and (min-width: 992px) {
  .multisite-wisloh .view-km-marken.view-display-id-block_1 .view-content .item-list > ul {
    justify-content: flex-end;
    padding-right: 70px; } }

.multisite-wisloh .page-header #block-views-km-marken-block-1 {
  bottom: 34px; }

.multisite-wisloh .view-km-marken.view-display-id-block_1 .views-row > .inner {
  max-width: 80px; }

#sliding-popup {
  position: fixed;
  z-index: 9999999999;
  bottom: 0;
  left: 0;
  width: 100% !important;
  background: #3c3c3c !important;
  color: #fff;
  padding: 15px 10px;
  font-size: 14px; }
  #sliding-popup .popup-content {
    background: none;
    color: #fff;
    border: 0 none;
    max-width: 1170px;
    margin: 0 auto;
    background: none; }
    #sliding-popup .popup-content__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-flow: row wrap;
      margin-left: -1rem;
      margin-right: -1rem; }
      @media only screen and (min-width: 992px) {
        #sliding-popup .popup-content__inner {
          flex-flow: nowrap; } }
      #sliding-popup .popup-content__inner > * {
        padding-left: 1rem;
        padding-right: 1rem; }
    @media only screen and (min-width: 768px) {
      #sliding-popup .popup-content {
        flex-wrap: nowrap; } }
    #sliding-popup .popup-content::before {
      display: none; }
    #sliding-popup .popup-content #popup-text {
      margin-bottom: 15px;
      min-width: 300px;
      max-width: 100vw; }
      @media only screen and (min-width: 768px) {
        #sliding-popup .popup-content #popup-text {
          padding-right: 30px;
          margin-bottom: 0; } }
      #sliding-popup .popup-content #popup-text h2,
      #sliding-popup .popup-content #popup-text h3 {
        font-size: 1em; }
      #sliding-popup .popup-content #popup-text a {
        color: #fff; }
      #sliding-popup .popup-content #popup-text p {
        font-size: 1em;
        font-weight: 400;
        color: #fff !important;
        display: inline; }
        #sliding-popup .popup-content #popup-text p:last-of-type {
          margin: 0;
          display: inline; }
      #sliding-popup .popup-content #popup-text .find-more-button {
        background: none;
        border: 0 none;
        padding: 0;
        text-decoration: underline;
        font-size: 1em !important;
        cursor: pointer;
        color: currentColor; }
        #sliding-popup .popup-content #popup-text .find-more-button:hover {
          color: currentColor;
          text-decoration: underline; }
    #sliding-popup .popup-content .eu-cookie-compliance-categories {
      min-width: 280px; }
    #sliding-popup .popup-content .eu-cookie-compliance-category {
      margin-top: .65em;
      margin-bottom: .65em; }
      #sliding-popup .popup-content .eu-cookie-compliance-category__option {
        display: flex;
        align-items: start; }
        #sliding-popup .popup-content .eu-cookie-compliance-category__option input[type="checkbox"] {
          flex: 0 0 auto;
          margin: 0 .15rem 0 0 !important; }
          #sliding-popup .popup-content .eu-cookie-compliance-category__option input[type="checkbox"][disabled] {
            opacity: .75; }
          #sliding-popup .popup-content .eu-cookie-compliance-category__option input[type="checkbox"]:checked:not([disabled]) {
            filter: #5cb85c; }
            #sliding-popup .popup-content .eu-cookie-compliance-category__option input[type="checkbox"]:checked:not([disabled]) + label {
              color: #5cb85c; }
        #sliding-popup .popup-content .eu-cookie-compliance-category__option label {
          flex: 1 1 auto;
          padding: 0 !important;
          margin: 0 !important;
          line-height: 1.1; }
      #sliding-popup .popup-content .eu-cookie-compliance-category:not(:last-child) {
        margin-bottom: .35em; }
    #sliding-popup .popup-content .eu-cookie-compliance-category-description {
      font-size: 12px;
      line-height: 1.15;
      opacity: .65; }
    #sliding-popup .popup-content #popup-buttons {
      display: flex; }
      @media only screen and (max-width: 767px) {
        #sliding-popup .popup-content #popup-buttons {
          flex-direction: column;
          flex: 1; } }
      #sliding-popup .popup-content #popup-buttons button {
        margin-bottom: 2px !important;
        white-space: nowrap; }
    #sliding-popup .popup-content .eu-cookie-compliance-categories + #popup-buttons {
      display: block; }
      #sliding-popup .popup-content .eu-cookie-compliance-categories + #popup-buttons .button {
        display: block;
        width: 100% !important;
        margin-top: .2em !important;
        margin-bottom: .2em !important;
        text-align: center !important;
        font-size: 14px !important; }
        #sliding-popup .popup-content .eu-cookie-compliance-categories + #popup-buttons .button:not(.agree-button) {
          padding-top: .35em !important;
          padding-bottom: .35em !important; }
        #sliding-popup .popup-content .eu-cookie-compliance-categories + #popup-buttons .button.agree-button {
          border-color: #fff !important;
          font-weight: 700 !important; }
          #sliding-popup .popup-content .eu-cookie-compliance-categories + #popup-buttons .button.agree-button:hover {
            background-color: #fff !important;
            color: #626366 !important; }
  #sliding-popup .eu-cookie-withdraw-tab {
    font-size: 12px;
    padding: .75em;
    text-align: center;
    text-align: center;
    display: block;
    width: 100%;
    left: 0;
    top: 0;
    transform: translate(0, -100%); }
    @media (min-width: 544px) {
      #sliding-popup .eu-cookie-withdraw-tab {
        left: 30px;
        width: auto;
        display: inline; } }
  #sliding-popup.eu-cookie-withdraw-wrapper {
    padding-top: 0;
    padding-bottom: 0; }
    #sliding-popup.eu-cookie-withdraw-wrapper .eu-cookie-withdraw-banner {
      background: none;
      padding-top: 15px;
      padding-bottom: 15px; }
    #sliding-popup.eu-cookie-withdraw-wrapper .popup-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: row wrap; }
      #sliding-popup.eu-cookie-withdraw-wrapper .popup-content > * {
        padding-left: 1rem;
        padding-right: 1rem; }
  #sliding-popup .eu-cookie-compliance-hidden {
    display: none !important; }

#au-su-container {
  bottom: 95px !important; }
  @media (min-width: 768px) {
    #au-su-container {
      bottom: 15px !important; } }
